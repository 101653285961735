/* eslint-disable no-return-assign */
<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    class="tw-h-screen tw-max-h-screen grey lighten-3 pt-0 mt-0"
  >
    <v-layout
      justify-center
      pt-0
      mt-0
    >
      <v-flex
        id="card-container"
        md11
        pl-1
        pr-1
        pt-0
        mt-0
      >
        <material-card
          color="blue darken-4"
          title="Companies showing potential"
          text="This is a list of companies that show potential as sales targets"
          class="mt-6"
        >
          <v-combobox
            v-model="locationFilter"
            multiple
            clearable
            item-text="text" 
            item-value="value"
            :items="locations"
            label="Filter by region"
            @blur="applyLocationFilter"
          />
          
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="companies"
            :items-per-page="5"
              

            item-key="id"
            style="overflow-x: hidden; collspan:100%"
          >
            <template
              #headers="props"
            >
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon
                    v-if="header.sortable == true"
                    small
                  >
                    arrow_upward
                  </v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  <span class="black--text"> {{ item.company_name }}</span>
                  <v-icon
                    small
                    class="pl-2"
                    @click="openRecord(item.idcrm)"
                  >
                    mdi-open-in-new
                  </v-icon>
                </td>
                <td
                  class="text-xs-center"
                  width="3%"
                >
                  {{ item.company_rank }}
                </td>
                <td
                  class="text-xs-center"
                  width="30%"
                >
                  <v-layout
                    align-center
                    justify-center
                    row
                    fill-height
                  >
                    <v-btn
                      slot="activator"
                      class="v-btn--simple"
                      color="blue darken-1"
                      small
                      text
                      icon
                      @click="viewCompany(item.company_id)"
                    >
                      <v-icon color="blue darken-1">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                    <v-menu
                      bottom
                      offset-y
                    >
                      <template #activator="{ on }">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="blue darken-1"
                          small
                          text
                          icon
                          v-on="on"
                        >
                          <v-icon color="blue darken-2">
                            mdi-calendar-clock
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item
                            v-for="(postpone, id) in postponeOptions"
                            :key="id"
                            @click="postponeCompany(postpone.date, item)"
                          >
                            <v-list-item-content>{{ postpone.name }}</v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                    <v-menu
                      bottom
                      offset-y
                    >
                      <template #activator="{ on }">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="blue darken-1"
                          small
                          text
                          icon
                          v-on="on"
                        >
                          <v-icon color="blue darken-2">
                            mdi-account-arrow-left
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item
                            v-for="(staff_member, id) in staff"
                            :key="id"
                            @click="assignCompany(staff_member, item)"
                          >
                            <v-list-item-content>{{ staff_member.name }}</v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </v-layout>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
        <v-dialog
          v-model="companyDetailView"
          m-0
          
         
          style="overflow-y: hidden !important"
          attach="#card-container"
        >
          <v-card
            height="500"
            class="overflow-hidden"
          >
            <CompanyDetailView />
            <v-card-actions
              class="pa-0 ma-0 white"
            >
              <v-btn
                color="blue darken-1"
                text
                @click="companyDetailView=false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loading"
      max-width="450px"
      persistent
    >
      <v-card>
        <v-card-title class="subheading justify-center font-weight-light pt-3 pb-3">
          Talking to the mother ship
        </v-card-title>
        <v-progress-linear
          :indeterminate="true"
          height="4"
          class="pa-0 ma-0"
          color="blue"
        />
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert"
      :color="alert_color"
      top
      absolute
    >
      {{ alert_message }}
      <v-btn
        :class="alert_color"
        text
        dark
        @click="alert = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      alert_message: '',
      alert: false,
      alert_color: '',
      loading: true,
      companyDetailView: false,
      company: '',
      pagination: {
        sortBy: 'rank',
        descending: true,
        rowsPerPage: 5
      },
      selected: [],
      companies: [],
      staff: [],
      locations:[
        {
          text: "Europe",
          value: "eu"
        },
        {
          text: "North America (USA & Canada)",
          value: "na"
        },
        {
          text: "Asia",
          value: "as"
        }       
      ],
      locationFilter:[],
      postponeOptions: [
        {
          id: 1,
          name: '3 Months',
          date: this.$moment().add(3, 'months').format('YYYY-MM-DD')
        },
        {
          id: 2,
          name: '6 Months',
          date: this.$moment().add(6, 'months').format('YYYY-MM-DD')
        },
        {
          id: 3,
          name: '12 Months',
          date: this.$moment().add(364, 'days').format('YYYY-MM-DD')
        }

      ],
      headers: [
        {
          sortable: false,
          text: 'Name',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Rank',
          value: 'rank'
        },
        {
          sortable: false,
          align: 'center',
          text: 'Actions',
          value: 'actions'
        }
      ],
      assignedto: [
        'No Sales',
        'Web User'
      ]
    }
  },
  computed: {
    token () {
      return this.$store.getters.token
    }
  },
  mounted () {
    const headers = {
      'Content-Type': 'application/json',
      'OAuth-Token': this.$store.getters.token 
    }
    this.$http.post(this.$api_url + 'companies/list', {
    }, { headers: headers } )
      .then(response => {
        this.companies = response.data
        this.loading = false
      })
    this.$http.get(this.$api_url + 'sales-staff', {
      headers: {
        'OAuth-Token': this.token
      }
    })
      .then(response => {
        this.staff = response.data
      })
  },
  methods: {
    viewCompany (id) {
      this.companyDetailView = true
      this.$store.dispatch('updateCompany', id)
    },
    assignCompany (staff, company) {
      this.loading = true
      this.$http.post(this.$api_url + 'sugar/update', {
        token: this.token,
        record_type: 'Accounts',
        record_id: company.idcrm,
        body: {
          assigned_user_name: staff.user_name,
          assigned_user_id: staff.crmid

        }
      })
        .then(response => {
          const index = this.companies.indexOf(company)
          this.loading = false
          this.companies.splice(index, 1)
          this.alert = true
          this.alert_color = 'green'
          this.alert_message = company.company_name + ' assigned to ' + staff.user_name
        })
        .catch(error => {
          this.loading = false
          this.alert = true
          this.alert_color = 'red'
          console.log(error)
          this.alert_message = 'Ooops! Something went wrong'
        })
    },
    postponeCompany (date, company) {
      this.loading = true

      this.$http.post(this.$api_url + 'sugar/update', {
        token: this.token,
        record_type: 'Accounts',
        record_id: company.idcrm,
        body: {
          reload_for_sales_on_c: date
        }
      })
        .then(response => {
          const index = this.companies.indexOf(company)
          this.loading = false
          this.companies.splice(index, 1)
          this.alert = true
          this.alert_color = 'green'
          this.alert_message = company.company_name + ' won´t come up ' + this.$moment(date).fromNow()
        })
        .catch(error => {
          this.loading = false
          this.alert = true
          this.alert_color = 'red'
          console.log(error)
          this.alert_message = 'Ooops! Something went wrong'
        })
    },
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.companies.slice()
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    openRecord (idcrm) {
      window.open('http://sugar.xprt.com/#/Accounts/' + idcrm, '_blank')
    },
    applyLocationFilter(){
      this.loading = true
      let selection=[]
      this.locationFilter.forEach(item => {
          selection.push(item.value)
      });

      const headers = {
        'Content-Type': 'application/json',
        'OAuth-Token': this.$store.getters.token 
      }
      this.$http.post(this.$api_url + 'companies/list', {
            locations:selection
      }, { headers: headers } )
      .then(response => {
        this.companies = response.data
        this.loading = false
      })
    }
  }
}
</script>
